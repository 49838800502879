<template>
  <form @keyup.enter="saveData">
    <b-field grouped class="column-direction-touch">
      <b-field
        label="Enquadramento da Empresa"
        :type="campoInvalido('companySize', 'data') ? 'is-danger' : ''"
        :message="
          campoInvalido('companySize', 'data')
            ? 'Enquadramento da Empresa é um campo obrigatório'
            : ''
        "
      >
        <b-select
          expanded
          placeholder="Enquadramento da Empresa"
          :disabled="disableFields"
          v-model="$v.data.companySize.$model"
          @input="changeCompanySize"
          @click.native="$v.data.companySize.$touch()"
        >
          <option v-for="option in companySizeOptions" :key="option.id" :value="option.value">
            {{ option.name }}
          </option>
        </b-select>
      </b-field>

      <b-field label="Simples Nacional" v-show="['ME', 'EPP'].indexOf(data.companySize) > -1">
        <b-switch
          size="is-medium"
          v-model="$v.data.simpleNational.$model"
          :disabled="disableFields"
        />
      </b-field>

      <b-field
        v-show="data.simpleNational"
        class="registry-date-field"
        label="Data do Registro na Junta Comercial"
        :type="campoInvalido('simpleNationalDate', 'data') ? 'is-danger' : ''"
        :message="
          campoInvalido('simpleNationalDate', 'data')
            ? 'Selecione uma data válida para o registro'
            : ''
        "
      >
        <b-datepicker
          editable
          placeholder="Data do Registro na Junta Comercial"
          :disabled="disableFields"
          icon="calendar-today"
          v-mascara:data
          v-model="maskValues.simpleNationalDate"
          @input="valor => atualizarData('simpleNationalDate', valor, 'data', 'maskValues')"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
        >
          <button
            class="button is-primary br-4 mr-1"
            @click.prevent.stop="inserirHoje('simpleNationalDate')"
          >
            <b-icon icon="calendar-today" />
            <span>Hoje</span>
          </button>

          <button
            class="button is-danger br-4"
            @click.prevent.stop="limparData('simpleNationalDate')"
          >
            <b-icon icon="close" />
            <span>Limpar</span>
          </button>
        </b-datepicker>
      </b-field>
    </b-field>

    <button
      class="button is-primary is-fullwidth save-button"
      :class="{ ' is-loading': loading }"
      :disabled="disableFields"
      @click.prevent.stop="saveData"
    >
      Salvar
    </button>
  </form>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { required, requiredIf } from 'vuelidate/lib/validators';

import fieldMixin from '@/mixins/formulario';

export default {
  name: 'SellerCompanySize',
  mixins: [fieldMixin],
  data() {
    const companySizeOptions = [
      { id: 1, name: 'ME', value: 'ME' },
      { id: 2, name: 'MEI', value: 'MEI' },
      { id: 3, name: 'EPP', value: 'EPP' },
      { id: 4, name: 'Outros', value: 'OUTROS' },
      { id: 5, name: 'Cooperativa', value: 'COOPERATIVA' },
      {
        id: 6,
        name: 'Cooperativa Direito de Preferência',
        value: 'COOPERATIVA_PREFERENCIA',
      },
    ];
    const data = {
      companySize: '',
      simpleNational: false,
      simpleNationalDate: new Date(),
    };
    const maskValues = {
      simpleNationalDate: new Date(),
    };

    return {
      companySizeOptions,
      data,
      loading: false,
      maskValues,
    };
  },
  async beforeRouteLeave(to, from, next) {
    if (this.logged && this.$v.data.$anyDirty) {
      const exit = await this.$confirmacao({
        message: 'Os dados serão perdidos. Tem certeza que deseja continuar?',
      });
      return next(exit);
    }
    return next();
  },
  validations: {
    data: {
      companySize: { required },
      simpleNational: { required: false },
      simpleNationalDate: {
        required: requiredIf(function validarDataSimplesNacional() {
          return this.data.simpleNational;
        }),
      },
    },
  },
  computed: {
    ...mapState('companies', ['company']),
    ...mapGetters({ user: 'getUser', logged: 'getLogged' }),
    disableFields() {
      return (
        this.company.status === 'INABILITADA' ||
        !this.user.empresas.some(
          companyUser => companyUser.id === this.company.id && companyUser.editar_empresa,
        )
      );
    },
  },
  watch: {
    company(company) {
      if (company) {
        this.data.companySize = company.companySize;
        this.data.simpleNational = company.simpleNational;
        this.data.simpleNationalDate = company.simpleNationalDate;
        this.maskValues.simpleNationalDate = company.simpleNationalDate;
      }
    },
  },
  methods: {
    ...mapActions('companies', ['saveCompany']),
    changeCompanySize(value) {
      if (['ME', 'EPP'].indexOf(value) === -1) {
        this.data.simpleNational = false;
      }
    },
    async saveData() {
      if (this.validate()) {
        const updatedCompanySize =
          this.company.status !== 'CADASTRADA' &&
          this.data.companySize !== this.company.companySize;

        if (updatedCompanySize) {
          const proceed = await this.$confirmacao({
            message:
              'Deseja confirmar a alteração de enquadramento? Você deverá solicitar a validação da SelCorp.',
            cancelText: 'Cancelar',
            confirmText: 'Continuar',
            type: 'is-warning',
            hasIcon: true,
          });
          if (!proceed) {
            return;
          }
        }

        this.loading = true;
        try {
          const data = { ...this.data };
          await this.saveCompany(data);
          this.$alerta('Dados salvos', 'is-success');
          this.$v.data.$reset();
          if (updatedCompanySize) this.$router.push({ name: 'SellerDocumentation' });
        } catch (error) {
          this.$alerta(error.message, 'is-danger');
        }
        this.loading = false;
      }
    },
    validate() {
      if (this.$v.data.$invalid || (this.simpleNational && !this.data.simpleNationalDate)) {
        this.$v.data.$touch();
        this.$alerta('Preencha todo os dados');
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.registry-date-field {
  min-width: 350px;
}
</style>
